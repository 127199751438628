import React from 'react';

const Button = ({ title = 'Buy $TRUEUFO →' }: any) => {
  return (
    <button className='border-2 border-neonGreen px-8 py-4 mt-8 text-neonGreen  font-bold hover:bg-neonGreen hover:text-black transition duration-300'>
      {title}
    </button>
  );
};

export default Button;
