import React from 'react';
import { Col } from 'react-grid-system';

const CustomCol = ({ children, ...props }: any) => {
  return (
    <Col xl={10} offset={{ xl: 1 }} {...props}>
      {children}
    </Col>
  );
};

export default CustomCol;
