import { useEffect } from 'react';
import './App.css';
import { Container, Row, ScreenClassRender } from 'react-grid-system';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Socials from './Socials';
import Img from './Img';
import CustomCol from './CustomCol';

gsap.registerPlugin(ScrollTrigger);

function Layout({ children }: any) {
  useEffect(() => {
    // section-1-video

    ScrollTrigger.create({
      anticipatePin: 1,
      pin: '#section-1-video',
      trigger: '#section-1',
      // start: isSmallScreen ? 'top 4%' : 'top 18%',
      end: 'bottom bottom',
    });

    gsap.to('#section-1-video', {
      opacity: 1,
      scale: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '#section-1',
        start: '20% center',
        end: '+=80%',
        scrub: true,
        // markers: true,
      },
    });

    gsap.to('#section-1-video-label', {
      opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '#section-1',
        start: '50% center',
        end: '+=60%',
        scrub: true,
        // markers: true,
      },
    });

    // section-2

    gsap.to('#section-2', {
      opacity: 1,
      scale: 1,
      trigger: '#section-2',
      scrollTrigger: {
        trigger: '#section-2',
        start: 'top bottom',
        end: '+=50%',
        scrub: true,
        // markers: true,
      },
    });
  }, []);

  return (
    <div className='font-mono' style={{ overflow: 'hidden' }}>
      <ScreenClassRender
        render={(sc: string) =>
          !['md', 'lg', 'xl', 'xxl'].includes(sc) ? null : (
            <div
              style={{
                width: '100%',
                position: 'relative',
              }}
            >
              <Img
                src='shape_5.png'
                style={{
                  position: 'absolute',
                  top: -450,
                  height: 800,
                  right: -250,
                  zIndex: -1,
                  objectFit: 'contain',
                  opacity: 0.33,
                }}
              />
            </div>
          )
        }
      />
      <Container>
        <Row>
          <CustomCol>
            <div
              className='flex items-end justify-between flex-row'
              style={{
                position: 'absolute',
                top: 0,
                height: 48,
                width: 'calc(100% - 40px)',
              }}
            >
              <ScreenClassRender
                render={(sc: string) => (
                  <a
                    href='/'
                    style={{
                      zIndex: 9999,
                    }}
                  >
                    <Img
                      src='trueufo.svg'
                      style={{
                        height: ['md', 'lg', 'xl', 'xxl'].includes(sc)
                          ? 30
                          : 18,
                      }}
                    />
                  </a>
                )}
              />

              <div>
                <Socials />
              </div>
            </div>
          </CustomCol>
        </Row>
      </Container>
      {children}
      <Container>
        <Row className='mt-16'>
          <CustomCol>
            <ScreenClassRender
              render={(sc: string) => (
                <div
                  className={`flex items-center justify-between mt-12 ${
                    ['md', 'lg', 'xl', 'xxl'].includes(sc)
                      ? 'flex-row'
                      : 'flex-col'
                  }`}
                  style={{
                    width: '100%',
                  }}
                >
                  <div className='font-bold'>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.uaptheory.com/'
                    >
                      <p>Buy $TRUEUFO →</p>
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.uaptheory.com/'
                    >
                      <p className='mt-2'>UAP Theory →</p>
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.uaptheory.com/'
                    >
                      <p className='mt-2'>Community →</p>
                    </a>
                  </div>
                  <div className='mt-10'>
                    <Socials />
                  </div>
                </div>
              )}
            />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol>
            <div
              className='flex items-center justify-center flex-row mt-12'
              style={{
                width: '100%',
              }}
            >
              <Img
                src='drake_equation.svg'
                className='mb-10'
                style={{
                  height: 24,
                }}
              />
            </div>
          </CustomCol>
        </Row>
      </Container>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Img
          src='shape_8.png'
          style={{
            position: 'absolute',
            top: -700,
            height: 800,
            left: -250,
            zIndex: -1,
            objectFit: 'contain',
            opacity: 0.8,
            transform: 'rotate(-15deg)',
          }}
        />
      </div>
    </div>
  );
}

export default Layout;
