import React from 'react';
import { ScreenClassRender } from 'react-grid-system';
import Img from './Img';

const Socials = () => {
  return (
    <ScreenClassRender
      render={(sc: string) => {
        const height = ['md', 'lg', 'xl', 'xxl'].includes(sc) ? 26 : 19;
        const marginRight = ['md', 'lg', 'xl', 'xxl'].includes(sc) ? 24 : 16;

        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <a href='https://t.me' rel='noreferrer' target='_blank'>
                <Img
                  src='/telegram.svg'
                  style={{
                    height,
                    marginRight,
                  }}
                />
              </a>
              <a href='https://twitter.com/' rel='noreferrer' target='_blank'>
                <Img
                  src='/twitter.svg'
                  style={{
                    height,
                    marginRight,
                  }}
                />
              </a>
              <a href='https://medium.com' rel='noreferrer' target='_blank'>
                <Img
                  src='/medium.svg'
                  style={{
                    height,
                    marginRight,
                  }}
                />
              </a>
              <a href='https://github.com/' rel='noreferrer' target='_blank'>
                <Img
                  src='/github.svg'
                  style={{
                    height,
                  }}
                />
              </a>
            </div>
          </>
        );
      }}
    />
  );
};

export default Socials;
