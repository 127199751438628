import './index.css';
import './App.css';
import ReactDOM from 'react-dom';
import router from './router';
import history from './history';

const render = async (location: any) => {
  const element = await router.resolve(location);
  // @ts-ignore
  ReactDOM.render(element, document.getElementById('root'));
};

// Listening for the history changes to the current location
history.listen(render);

// Initial Rendering for the initial location
render(history.location);
