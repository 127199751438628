import React from 'react';
import { Container, Row, ScreenClassRender } from 'react-grid-system';
import Typist from 'react-typist';
import CustomCol from './CustomCol';
import Button from './Button';

const BuyPage = () => {
  return (
    <Container>
      <Row>
        <CustomCol md={12} lg={8} xl={6}>
          <ScreenClassRender
            render={() => (
              <div
                style={{
                  marginTop: '20vh',
                }}
              >
                <p className='text-2xl lg:text-6xl font-bold text-left  text-white'>
                  How to buy <br />
                  <span className='font-extralight text-neonGreen'>
                    <Typist
                      cursor={{
                        element: '_',
                      }}
                    >
                      <span className='font-bold text-neonGreen'>$TRUEUFO</span>
                    </Typist>
                  </span>
                </p>
                <p className='text-base lg:text-lg font-bold text-left  text-white mt-6'>
                  If you haven't bought tokens on Binance Smart Chain (BSC)
                  before, please read the instructions below.
                  <br />
                </p>
                <Button title='Buy on PancakeSwap →' />
              </div>
            )}
          />
        </CustomCol>
      </Row>
      <Row className='mt-16'>
        {[
          {
            content: (
              <>
                <p className='text-xl font-bold text-left  text-white'>
                  1. Download{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://metamask.io/'
                  >
                    Metamask
                  </a>{' '}
                  or{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://trustwallet.com/smart-chain-wallet/'
                  >
                    TrustWallet
                  </a>
                  .
                </p>
                <p className='text-base  text-left  text-white mt-6'>
                  This is where your $TRUEUFO tokens will be stored.
                </p>
              </>
            ),
          },
          {
            content: (
              <>
                <p className='text-xl font-bold text-left  text-white'>
                  2. Connect your wallet to the BSC network.
                </p>
                <p className='text-base  text-left  text-white mt-6'>
                  Metamask instructions are{' '}
                  <a
                    className='font-bold'
                    target='_blank'
                    rel='noreferrer'
                    href='https://coinguides.org/binance-smart-chain-metamask/'
                  >
                    here
                  </a>
                  , and TrustWallet instructions are{' '}
                  <a
                    className='font-bold'
                    target='_blank'
                    rel='noreferrer'
                    href='https://academy.binance.com/en/articles/connecting-trust-wallet-to-binance-smart-chain-bsc'
                  >
                    here
                  </a>
                  .
                </p>
              </>
            ),
          },
          {
            content: (
              <>
                <p className='text-xl font-bold text-left  text-white'>
                  3. Go to{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://exchange.pancakeswap.finance/#/swap'
                  >
                    PancakeSwap
                  </a>
                </p>
                <p className='text-base  text-left  text-white mt-6'>
                  From the PancakeSwap side menu, go to "Trade" → "Exchange" →
                  "Select a Currency", and paste the $TRUEUFO contract address
                  below in the field.
                </p>
                <br />
                <p className='text-lg'>
                  Address:{' '}
                  <span className='font-bold'>0xef24FAKEFAKEKFAKEKFAKE</span>
                </p>
              </>
            ),
          },
          {
            content: (
              <>
                <p className='text-xl font-bold text-left  text-white'>
                  4. Update the slippage and enter an amount to buy
                </p>
                <p className='text-base  text-left  text-white mt-6'>
                  Slippage should be set to 12% to account for $TRUEUFO
                  liquidity and reward fees.
                </p>
                <br />
              </>
            ),
          },
          {
            content: (
              <>
                <p className='text-xl font-bold text-left  text-white'>
                  5. Press Swap
                </p>
                <p className='text-base  text-left  text-white mt-6'>
                  Confirm the transaction and wait until it is confirmed.
                  <br />
                  <br />
                  Welcome to TrueUFO.
                </p>
                <br />
              </>
            ),
          },
        ].map(({ content }, i) => (
          <CustomCol xl={7}>
            <div className={`py-6 mt-4`}>{content}</div>
          </CustomCol>
        ))}
      </Row>
    </Container>
  );
};

export default BuyPage;
