import React, { useEffect } from 'react';
import './App.css';
import { Container, Row, ScreenClassRender } from 'react-grid-system';
import Typist from 'react-typist';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Img from './Img';
import Button from './Button';
import CustomCol from './CustomCol';

gsap.registerPlugin(ScrollTrigger);

const BuyButton = () => {
  return (
    <a href='/buy'>
      <Button />
    </a>
  );
};

function HomePage() {
  useEffect(() => {
    // section-1-video

    ScrollTrigger.create({
      anticipatePin: 1,
      pin: '#section-1-video',
      trigger: '#section-1',
      // start: isSmallScreen ? 'top 4%' : 'top 18%',
      end: 'bottom bottom',
    });

    gsap.to('#section-1-video', {
      opacity: 1,
      scale: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '#section-1',
        start: '20% center',
        end: '+=80%',
        scrub: true,
        // markers: true,
      },
    });

    gsap.to('#section-1-video-label', {
      opacity: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: '#section-1',
        start: '50% center',
        end: '+=60%',
        scrub: true,
        // markers: true,
      },
    });

    // section-2

    gsap.to('#section-2', {
      opacity: 1,
      scale: 1,
      trigger: '#section-2',
      scrollTrigger: {
        trigger: '#section-2',
        start: 'top bottom',
        end: '+=50%',
        scrub: true,
        // markers: true,
      },
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
          // commenting this centers horiontaly
          position: 'absolute',
        }}
      >
        <div
          id='section-1-video'
          style={{
            width: '100vw',
            height: '100vh',
            maxWidth: 1200,
            overflowY: 'visible',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.18,
            transform: 'scale(0.75)',
          }}
        >
          <video
            className='video-1'
            autoPlay
            muted
            loop
            preload='auto'
            style={{
              backgroundColor: '#606060',
              width: '100%',
              // paddingBottom: '40%',
              height: 'auto',
              background: 'url(video_fallback.png) no-repeat center',
              backgroundSize: 'contain',
              objectFit: 'contain',
            }}
            // poster='video_fallback.png'
          >
            <source src='video_4.mp4' type='video/mp4' />
          </video>
          <p
            id='section-1-video-label'
            className='mt-4  text-center'
            style={{
              opacity: 0,
              zIndex: 1000,
            }}
          >
            {/* <a
              target='_blank'
              rel='noreferrer'
              className='underline'
              href='https://www.youtube.com/watch?v=WyuHDr6xksg'
            > */}
            Puerto Rico, Rafael Hernandez Airport, 2013
            {/* </a> */}
          </p>
        </div>
      </div>
      <Container>
        <Row
          id='section-1'
          style={{
            height: '230vh',
          }}
        >
          <CustomCol md={12} lg={8} xl={6}>
            <ScreenClassRender
              render={(sc: string) => (
                <div
                  style={{
                    marginTop: ['md', 'lg', 'xl', 'xxl'].includes(sc)
                      ? '30vh'
                      : '18vh',
                  }}
                >
                  <p className='text-2xl lg:text-6xl font-bold text-left  text-white'>
                    Let's find the <br />
                    <span className='text-transparent text-terminalAmber'>
                      Truth{' '}
                    </span>{' '}
                    about <br />
                    <span className='font-extralight text-neonGreen'>
                      <Typist
                        cursor={{
                          element: '_',
                        }}
                      >
                        <span className='font-bold text-neonGreen'>
                          UFOs
                          <Typist.Backspace count={4} delay={3000} />
                          Roswell
                          <Typist.Backspace count={7} delay={2200} />
                          Area 51
                          <Typist.Backspace count={7} delay={2200} />
                          Reality
                        </span>
                      </Typist>
                    </span>
                  </p>
                  <p className='text-base lg:text-lg font-bold text-left  text-white mt-8'>
                    Governments have kept secrets for too long. <br />
                    {/* It's time to start a revolution they can't ignore. <br /> */}
                    $TRUEUFO is a token that funds UFO research and lobbying,
                    and gives us power to find the truth.
                  </p>
                  <BuyButton />
                </div>
              )}
            />
          </CustomCol>
        </Row>
        <div
          style={{
            height: 100,
            width: '100%',
            position: 'absolute',
          }}
        >
          <Img
            src='shape_7.png'
            style={{
              position: 'absolute',
              top: -450,
              height: 800,
              left: -500,
              zIndex: -1,
              objectFit: 'contain',
              opacity: 0.8,
              transform: 'rotate(25deg)',
            }}
          />
        </div>
        <Row
          id='section-2'
          style={{
            transform: 'scale(0.9)',
            opacity: 0.33,
          }}
        >
          {[
            {
              content: (
                <>
                  <Img src='exp.png' style={{ height: 80 }} className='mb-8' />
                  <p className='text-xl font-bold text-left  text-white'>
                    UFO Research and Lobbying Fund
                  </p>
                  <p className='text-base  text-left  text-white mt-8'>
                    10% of the $TRUEUFO supply will fund{' '}
                    <span className='text-neonGreen font-bold'>
                      UFO Research and Government Lobbying
                    </span>{' '}
                    to get answers the world needs and deserves.
                  </p>
                </>
              ),
            },
            {
              content: (
                <>
                  <Img
                    src='books_2.png'
                    style={{ height: 80 }}
                    className='mb-8'
                  />
                  <p className='text-xl font-bold text-left  text-white'>
                    The Future of Uncensored Truth
                  </p>
                  <p className='text-base  text-left  text-white mt-8'>
                    The TrueUFO team will build a $TRUEUFO powered{' '}
                    <span className='text-terminalAmber font-bold'>
                      Uncensorable Social Media Platform{' '}
                    </span>
                    in response to recent centralized platform censorship.
                  </p>
                </>
              ),
            },
            {
              content: (
                <>
                  <Img
                    src='deep_field.png'
                    style={{ height: 80 }}
                    className='mb-8'
                  />
                  <p className='text-xl font-bold text-left  text-white'>
                    Automatic Liquidity and Rewards
                  </p>
                  <p className='text-base  text-left  text-white mt-8'>
                    All $TRUEUFO transactions are taxed 8% to generate{' '}
                    <span className='text-neonGreen font-bold'>
                      Liquidity and Rewards
                    </span>{' '}
                    for $TRUEUFO holders, strengthening our cause.
                  </p>
                </>
              ),
            },
          ].map(({ content }, i) => (
            <CustomCol
              md={12}
              lg={4}
              xl={3.33}
              offset={{ xl: i === 0 ? 1 : 0 }}
            >
              <div className={`py-6 mt-4`}>{content}</div>
            </CustomCol>
          ))}
          <CustomCol lg={12}>
            <div
              className='flex items-center justify-center flex-row'
              style={{
                width: '100%',
              }}
            >
              <BuyButton />
            </div>
          </CustomCol>
        </Row>
        <Row className='mt-16 mb-16'>
          <CustomCol
            md={12}
            lg={6}
            xl={5}
            className='flex align-center justify-center flex-col'
          >
            <p className='text-base text-left  text-white mt-8'>
              "I have never seen anything that has the performance, the
              acceleration — keep in mind{' '}
              <span className='text-terminalAmber font-bold'>
                this thing had no wings
              </span>{' '}
              ... Navy ships reported{' '}
              <span className='text-neonGreen font-bold'>
                objects that were dropping out of the sky from 80,000 feet
              </span>{' '}
              and going straight back up."
            </p>
            <table className='mt-4'>
              <tr>
                <td>-&emsp;</td>
                <td>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.youtube.com/watch?v=ygB4EZ7ggig'
                    className='underline text-base font-bold text-left  text-white mt-8'
                  >
                    David Fravor, Former USA Navy Pilot
                  </a>
                </td>
              </tr>
            </table>
          </CustomCol>
          <CustomCol
            lg={6}
            xl={5}
            className='flex align-center justify-center flex-col'
          >
            <Img
              src='david_fravor.jpg'
              className='mt-12'
              style={{ maxHeight: 350, objectFit: 'contain' }}
            />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol>
            <div className='mt-16' />
            <p className='text-center text-lg font-bold'>
              100 Billion $TRUEUFO Total Supply
            </p>
            <p className='text-center text-lg text-terminalAmber font-bold'>
              15 Billion Team Tokens Burned
            </p>
            <div className='mt-12' />
            <div className='timeline'>
              {[
                {
                  title: 'Q2 2021 CE',
                  contents: [
                    '$TRUEUFO Launch',
                    'Team Tokens Burned',
                    'Marketing Campaign',
                    'Community Partnerships',
                    'Team Expansion',
                  ],
                },
                {
                  title: 'Q3 2021 CE',
                  contents: [
                    'UFO Research Donations',
                    'USA Congress Lobbying',
                    <span>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.seti.org/'
                      >
                        SETI
                      </a>{' '}
                      Donations
                    </span>,
                    'Uncensorable Social Media Platform Development',
                    <span>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.youtube.com/user/powerfuljre'
                      >
                        Joe Rogan Podcast
                      </a>{' '}
                      Sponsorship
                    </span>,
                    'Major Exchange Listings',
                    'Security Audits',
                  ],
                },
                {
                  title: 'Q4 2021 CE',
                  contents: [
                    'Social Media Platform Launch',
                    'Decentralized UFO Research Program Launch',
                    <span>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.youtube.com/watch?v=BEWz4SXfyCQ'
                      >
                        Bob Lazar
                      </a>{' '}
                      and{' '}
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://en.wikipedia.org/wiki/Jacques_Vall%C3%A9e'
                      >
                        Jacques Vallée
                      </a>{' '}
                      Community Interviews
                    </span>,
                    'Science Education Donations',
                    'Research Fund DAO',
                  ],
                },
              ].map(({ title, contents }, i) => (
                <div className={`container ${i % 2 === 0 ? 'left' : 'right'}`}>
                  <div className='content hover:text-neonGreen hover:border-neonGreen transition duration-300 '>
                    <p className='text-xl font-bold'>{title}</p>
                    <table className='mt-4'>
                      {contents.map((c) => (
                        <tr>
                          <td>-&emsp;</td>
                          <td>{c}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                bottom: 0,
                height: 100,
                width: '100%',
                position: 'absolute',
              }}
            >
              <Img
                src='shape_2.png'
                style={{
                  position: 'absolute',
                  bottom: -200,
                  height: 800,
                  right: -400,
                  zIndex: -1,
                  objectFit: 'contain',
                  opacity: 0.33,
                }}
              />
            </div>
          </CustomCol>
          <CustomCol lg={12}>
            <div className='flex align-center justify-center'>
              <BuyButton />
            </div>
          </CustomCol>
        </Row>
        <Row className='mt-16 mb-16'>
          <CustomCol
            md={12}
            lg={6}
            xl={5}
            className='flex align-center justify-center flex-col'
          >
            <p className='text-base text-left text-white'>
              "If we long for our planet to be important, there is something we
              can do about it. We make our world significant by the{' '}
              <span className='text-terminalAmber font-bold'>
                courage of our questions
              </span>{' '}
              and by the{' '}
              <span className='text-neonGreen font-bold'>
                depth of our answers
              </span>
              ."
            </p>
            <p className='text-base font-bold text-left  text-white mt-8'>
              -&emsp;
              <a
                target='_blank'
                rel='noreferrer'
                href='https://en.wikipedia.org/wiki/Carl_Sagan'
                className='underline'
              >
                Carl Sagan, Cosmos
              </a>
            </p>
          </CustomCol>
          <CustomCol
            lg={6}
            xl={5}
            className='flex align-center justify-center flex-col'
          >
            <Img
              src='sagan_2.jpg'
              className='mt-12'
              style={{ maxHeight: 350, objectFit: 'contain' }}
            />
          </CustomCol>
        </Row>
      </Container>
    </>
  );
}

export default HomePage;
