import UniversalRouter from 'universal-router';
import HomePage from './HomePage';
import BuyPage from './BuyPage';
import Layout from './Layout';

export const basename = '';

const router = new UniversalRouter(
  [
    {
      path: '',
      action: async ({ next }) => {
        const children = await next();
        return <Layout>{children}</Layout>;
      },
      children: [
        {
          path: '/buy',
          action: () => <BuyPage />,
        },
        {
          path: '',
          action: () => <HomePage />,
        },
      ],
    },
  ],
  {
    baseUrl: basename,
    resolveRoute: (context, params) => {
      if (typeof context.route.action === 'function') {
        return context.route.action(context, params);
      }
    },
  }
);

export default router;
